import { mapState, mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

export default {
	name: 'ContractSheetDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		responses: {
			type: Array,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		contractId: {
			type: Number,
			required: false,
		},
		document: {
			type: Object,
			required: false,
		},
		permissions: {
			type: Object,
			required: false,
		},
		disablePdf: {
			type: Boolean,
			required: false,
		},
		contractName: {
			type: String,
			required: false,
		}
	},
	data() {
		return {
			valid: true,
			fields: [],
			loadingData: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			loading: false,
			loadingPdf: false,
		};
	},
	created() {
		//this.getManagements({ user_id: this.$store.state.auth?.user.id });
	},
	mounted() {},
	computed: {
		...mapState('document', ['template']),
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		myResponses: {
			get() {
				return this.responses || false;
			},
			set(value) {
				this.$emit('setResponses', value);
			},
		},
		validateButtonSave(){
			const activities = this.$store.state.auth.activities || [];
			const found = activities.some(
				(activity) =>
					activity.module_name == 'Contrato' &&
					activity.page_name == 'mostrarContrato' &&
					activity.activity_name == 'editarDocumentos'
			);
			return found;
		}
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.setValues();
			} else {
				this.closeDialog();
			}
		},
	},
	methods: {
		...mapActions('contract', ['updateContractSheet']),
		...mapActions('document', ['updateSheetAddendum']),

		async save() {
			const isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				let fields = [];
				this.fields.forEach((group) => {
					group.items.forEach((item) => {
						fields.push({ field_id: item.id, value: item.value });
					});
				});
				this.myResponses = fields;
				this.dialog = false;
			} else {
				this.showAlert('Completar Formulario', 'warning');
			}
		},

		generatePDF() {
			try {
				this.loadingPdf = true;
				const pdf = new jsPDF();
				let body = [];
		
				this.fields.map((group) => {
					body.push([{content: group.group, colSpan: 2, styles: {fontStyle: 'bold', fillColor: [200, 200, 200]}}]);	
					group.items.map((item) => {
						const found = this.myResponses.find(
							(res) => res.field_id == item.id
						);
						body.push([found.field.label, found.value]);	
					});	
				});
				autoTable(pdf, {
					head: [[`Ficha contractual`, this.contractName]],
					body: body,
				})
				pdf.save(`ficha_contractual_${this.contractName}.pdf`);
			} catch (error) {
				this.showError(error?.response || error.toString());
			} finally {
				this.loadingPdf = false;
			}
		},

		showError(error) {
			let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join(', ') : msmError;
			$EventBus.$emit('showSnack', 'error', msmError);
		},

		async update() {
			const isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				this.loading = true;
				let fields = [];
				this.fields.forEach((group) => {
					group.items.forEach((item) => {
						fields.push({ field_id: item.id, value: item.value });
					});
				});
				this.myResponses = fields;

				const data = {
					contractId: this.contractId,
					contract: {
						status: 'DRAFT',
						contract_field: fields,
					},
				};
				const { ok, response, error } = await this.updateContractSheet(data);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', response.message[0]);
					this.$emit('loadContract');
					this.dialog = false;
				} else {
					this.showAlert(error);
				}
				this.loading = false;
			} else {
				this.showAlert('Completar Formulario', 'warning');
			}
		},

		async updateAddendum() {
			const isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				this.loading = true;
				let fields = [];
				this.fields.forEach((group) => {
					group.items.forEach((item) => {
						fields.push({ field_id: item.id, value: item.value });
					});
				});

				const data = {
					id: this.document.id,
					body: {
						contract_document_field: fields,
					},
				};
				const { ok, response, error } = await this.updateSheetAddendum(data);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', response?.message[0]);
					this.$emit('loadContract');
					this.dialog = false;
				} else {
					this.showAlert(error);
				}
				this.loading = false;
			}
		},

		async sendOwner() {
			const isvalidated = this.$refs.addForm.validate();
			if (isvalidated) {
				this.loading = true;
				const { ok, response, error } = await this.saveOwner(this.owner_form);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', response.message[0]);
					this.dialog = false;
				} else {
					this.showAlert(error);
				}
				this.loading = false;
			}
		},

		showAlert(text = '', color = 'error') {
			if (color == 'error') {
				let msmError = text?.data?.message;
				msmError = Array.isArray(msmError) ? msmError.join(' <br> ') : msmError;
				$EventBus.$emit('showSnack', color, msmError);
			} else {
				$EventBus.$emit('showSnack', color, text);
			}
		},

		setValues() {
			let fields = [...(this.template?.fields || [])];
			if (
				Array.isArray(fields) &&
				Array.isArray(this.myResponses) &&
				this.myResponses.length > 0
			) {
				fields = fields.map((group) => {
					let items = group.items.map((item) => {
						const found = this.myResponses.find(
							(res) => res.field_id == item.id
						);
						return { ...item, value: found ? found.value : null };
					});
					return { ...group, items };
				});
			}
			this.fields = fields;
		},
		closeDialog() {
			this.fields = [];
			this.$nextTick(() => {
				if (this.$refs.form) {
					this.$refs.form.resetValidation();
				}
			});
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
	},
};
